.contact {
  padding: 0 20px;
  margin-top: 40px; }
  .contact a {
    text-decoration: underline; }
  @media (min-width: 768px) {
    .contact {
      padding: 0 20%; } }

.contact-info {
  margin-bottom: 40px;
  margin-right: 40px; }

.text {
  margin-bottom: 5px; }

.portrait {
  width: 100%;
  height: 100%;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .portrait {
      width: 60%;
      margin-bottom: 20px; } }

.media-object--contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 768px) {
    .media-object--contact {
      flex-direction: row;
      padding: 0 20%;
      margin-bottom: 30px; } }
