.home {
  text-align: center;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .home p {
      margin-bottom: 20px; } }

.showreel {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  margin-top: -2px;
  height: 0;
  margin-bottom: 40px;
  margin-top: 40px; }

.showreel iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.text-wrapper p {
  font-weight: bold;
  font-size: 20px; }
  @media (min-width: 768px) {
    .text-wrapper p {
      font-size: 22px; } }

@media (min-width: 768px) {
  .soundcloud-player {
    min-width: 550px; } }

.quote {
  font-style: italic; }
