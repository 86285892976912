.about {
  display: flex;
  align-items: center;
  margin-top: 20px; }

.media-object {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .media-object {
      margin-bottom: 20px; } }

.media-object-text {
  padding: 0 20px; }
  @media (min-width: 768px) {
    .media-object-text {
      margin-left: 40px; } }

.landscape-image {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  max-width: 1152px; }
  @media (min-width: 1768px) {
    .landscape-image {
      margin-bottom: 50px; } }

.image-collage {
  display: flex;
  max-width: 100vw;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center; }
  .image-collage img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .image-collage {
      flex-direction: row; }
      .image-collage img {
        width: 45%;
        height: 100%;
        margin-bottom: 40px; } }
  @media (min-width: 1768px) {
    .image-collage {
      padding: 0 20%; } }
