/* global styles */
* {
  box-sizing: border-box; }

body {
  background: #fff;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0; }

h1, h2, h3, h4 {
  font-family: "Roboto Slab", serif;
  margin: 0;
  font-weight: 300; }

h1 {
  font-weight: bold; }

h2 {
  text-transform: uppercase; }

a {
  color: #333; }

a {
  text-decoration: none; }

.link-button {
  color: #fff;
  border-bottom: none; }

p {
  margin: 0 0 10px; }
  @media (min-width: 1024px) {
    p {
      font-size: 20px; } }
  @media (min-width: 1768px) {
    p {
      padding: 0 20%; } }

.margin-bottom {
  margin-bottom: 30px; }

/* ----------------------------------*/
/* BUTTONS */
/* ----------------------------------*/
button {
  background-color: #306DA2;
  border-style: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  margin: 20px 0;
  min-width: 150px;
  outline: none;
  padding: 12px 20px;
  -webkit-appearance: none; }

button:disabled {
  cursor: not-allowed !important;
  opacity: .5; }

/* ----------------------------------*/
/* FLEX SHORTCUTS */
/* ----------------------------------*/
.flex-center {
  display: flex;
  justify-content: center; }

.flex-center-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

/* ----------------------------------*/
/* MEDIA QUERIES */
/* ----------------------------------*/
@media (min-width: 768px) {
  h1 {
    font-size: 34px; }
  p {
    margin: 0 0 16px; } }

.app {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2%; }
  @media (min-width: 768px) {
    .main-content {
      padding: 0 5%; } }
  @media (min-width: 1025px) {
    .main-content {
      padding: 0 20%; } }
