.footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 2%; }
  @media (min-width: 768px) {
    .footer {
      padding: 0 5%; } }
  @media (min-width: 1025px) {
    .footer {
      padding: 0 20%; } }
  .footer a {
    font-size: 25px; }
