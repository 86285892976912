.work {
  margin-top: 40px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px; }
  @media (min-width: 768px) {
    .work {
      flex-wrap: wrap;
      flex-direction: row;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px; } }
  @media (min-width: 1025px) {
    .work {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
  .work h2 {
    display: flex;
    align-items: flex-end; }
  .work p {
    margin: 0; }

.showcase-wrapper {
  display: grid;
  grid-template-rows: repeat(3, auto); }
  @media (min-width: 768px) {
    .showcase-wrapper {
      grid-template-rows: 28% auto auto; } }

.video-wrapper {
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 5px; }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
