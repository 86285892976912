.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #333;
  border-bottom: solid 2px #333;
  padding: 20px 2%; }
  @media (min-width: 768px) {
    .header {
      padding: 20px 5%; } }
  @media (min-width: 1025px) {
    .header {
      padding: 20px 20%; } }

.header-link {
  width: 100px; }
  @media (min-width: 768px) {
    .header-link {
      width: 150px; } }

.logo {
  height: 100%;
  width: 100%; }

.nav {
  padding: 0;
  display: flex;
  justify-content: space-around;
  margin: 0; }
  .nav li {
    list-style-type: none;
    padding: 0 10px; }

.link {
  position: relative;
  font-size: 1.25rem;
  color: #999;
  transition: all .3s ease-in-out;
  line-height: 1.6;
  padding: 0 0 2px 0; }

.link-text:hover {
  color: #171717;
  border-bottom: solid 2px #171717;
  padding-bottom: 2px; }
